import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/SEO";

function BedsteProteinPandekager() {
  return (
    <>
      <SEO
        title="Vores anbefalinger af de lækreste protein pandekager"
        description="Lad os være ærlige. Hvem elsker ikke protein pandekager? Det er en ren fornøjelse at spise, samtidig med at du bliver godt fyldt op med protein. Se her vores anbefalinger af protein pandekager."
      />
      <article className="flex flex-col items-center mx-auto max-w-4xl my-12">
        <h1 className="font-bold text-4xl mb-4">
          Vores anbefalinger af protein pandekager
        </h1>
        <p className="mb-4 text-2xl leading-10">
          Lad os være ærlige. Hvem elsker ikke protein pandekager? Det er en ren
          fornøjelse at spise, samtidig med at du bliver godt fyldt op med
          protein. Og med Bodylabs enorme udvalg, er der helt sikkert noget for
          enhver. Vi har her lavet en samling af de 3 protein pandekager, vi har
          haft meget fornøjelse af.
        </p>
        <h2 className="font-bold text-3xl my-4">
          Bodylab Protein Pandekager (500 g) - Cookies & Cream
        </h2>
        <p className="mb-4 text-2xl leading-10">
          Her har du vores absolut yndlings. Med Cookies & Cream smag, fik vi
          lige den pandekage som vi ønskede. Mums!
          <div className="flex my-4 justify-center">
            <Link
              to="/andet/bodylab-pancake--waffle-mix-cookies--cream-500g"
              className="btn green mr-2"
            >
              Læs mere
            </Link>
          </div>
        </p>
        <h2 className="font-bold text-3xl my-4">
          BodyLab Protein Pandekage & vaffel Mix Chocolate Chip
        </h2>
        <p className="mb-4 text-2xl leading-10">
          Her har du den mere klassiske pandekage smag. Cookies & Cream kan
          måske blive for sød for nogen, hvor du her med chocolate dip, kommer
          lidt væk fra det søde. Den SKAL prøves!
          <div className="flex my-4 justify-center">
            <Link
              to="/andet/bodylab-pancake--waffle-mix-chocolate-chip-500-g"
              className="btn green mr-2"
            >
              Læs mere
            </Link>
          </div>
        </p>
        <h2 className="font-bold text-3xl my-4">
          Bodylab Protein Pancake Mix (500 g) - Salted caramel
        </h2>
        <p className="mb-4 text-2xl leading-10">
          Vi vælger at kalde den her for "det sikre valg". Klassisk pandekage
          med saltet karamel, som alle kan lide det. Igen, MUMS!
          <div className="flex my-4 justify-center">
            <Link
              to="/andet/bodylab-pancake--waffle-mix-salted-caramel-500g"
              className="btn green mr-2"
            >
              Læs mere
            </Link>
          </div>
        </p>
      </article>
    </>
  );
}

export default BedsteProteinPandekager;
